.class-selectMember {
    position: relative;

    input#users-autocomplete {
        height: 45px !important;
        position: relative;
        bottom: 6px;
        padding-left: 20px;
    }

    .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-15ovows-MuiAutocomplete-root .MuiOutlinedInput-root {
        padding-right: 34px;
        position: relative;

        .MuiAutocomplete-endAdornment {
            top: 11px;
            bottom: 0px;
            position: absolute;
        }
    }

    > div:nth-child(2){
        z-index: 9;
        position: relative;
        max-width: 360px;
    }
}

.class-ProjectForm > div:nth-child(3) > .MuiGrid-item:nth-child(11) > div:nth-child(3) {
    z-index: 999;
}

.class-ProjectForm div.MuiAutocomplete-popper {
    background-color: transparent;
    border: none;
    > div {
        width:110%;
        position: absolute !important;
        left: -20px !important;
        background-color: rgb(214, 223, 229);
        border: 1px solid #707070;
    }
}

.class-filter-project .inputdropdown-component-first-element,
.class-CLIENT-SearchFilterComponent .inputdropdown-component-first-element{
    z-index: 999;

    > div.MuiAutocomplete-popper > div {
        width: 100%;
    }
}