.class-FilterComponent {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  width: fit-content;
}

.class-FilterComponent-clicked {
  min-width: 690px;
  position: relative;
}

.class-FilterComponent > span:nth-child(3) {
  background: #00253b;
  bottom: 0;
  left: 0;
  opacity: 0.5;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 0;
}

.class-FilterComponent > button:first-child {
  z-index: 2;
}

.class-FilterComponent > form {
  background-color: #f5f7f8;
  border-radius: 30px 0 30px 30px;
  box-shadow: 0 3px 60px #0000004d;
  display: grid;
  grid-gap: 40px 20px;
  grid-template-columns: calc(50% - 10px) calc(50% - 10px);
  min-width: 630px;
  padding: 20px;
  position: relative;
  position: absolute;
  right: 0;
  top: 62px;
  z-index: 1;
}

.class-FilterComponent > form > span:first-child {
  background: #f5f7f8;
  height: 62px;
  position: absolute;
  right: 0;
  top: -34px;
  width: 64px;
  z-index: 0;
}

.class-FilterComponent > form > button:nth-child(2) {
  grid-column: 2;
  height: fit-content;
  justify-content: revert;
}

.class-FilterComponent > form > div:nth-child(3) {
  display: flex;
  justify-content: space-around;
  grid-column: 1 / span 2;
}

.class-FilterComponent > form > div:nth-child(3) > div {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
}

.class-FilterComponent > form > div:nth-child(4),
.class-FilterComponent > form > div:nth-child(5),
.class-FilterComponent > form > div:nth-child(6),
.class-FilterComponent > form > div:nth-child(7) {
  position: relative;
}

.class-FilterComponent > form > div:nth-child(4),
.class-FilterComponent > form > div:nth-child(5) {
  z-index: 3;
}

/* .class-FilterComponent > form > div:nth-child(6),
.class-FilterComponent > form > div:nth-child(7) {
  z-index: 2;
} */
