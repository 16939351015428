.class-ProfileHeader {
  display: flex;
  justify-content: flex-end;
  gap: 25px;

  > button {
    width: 200px;
    border-radius: 1000px;
  }

  > button:disabled {
    color: #eaeef1 !important;
    background-color: gray !important;
  }

  > button:first-child {
    background-color: #eaeef1;
    color: #0083d2;
  }

  > button:last-child {
    background-color: #0083d2;
    color: #eaeef1;
  }
}
