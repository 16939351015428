.class-Certificate {
  div
    > input:not(.MuiOutlinedInput-input.MuiInputBase-input.MuiInputBase-inputSizeSmall.MuiInputBase-inputHiddenLabel.MuiInputBase-inputAdornedEnd) {
    background-color: #eaeef1 !important;
    border: 1px solid #707070 !important;
    height: 34px !important;
    border-radius: 1000px !important;
  }
  .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-sizeSmall.MuiInputBase-adornedEnd.MuiInputBase-hiddenLabel {
    background-color: #eaeef1 !important;
    border: 1px solid #707070 !important;
    height: 52px !important;
    border-radius: 1000px !important;
  }

  .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
    color: #0083d2;
  }

  tbody > tr > td:last-child button svg {
    color: #e11f1f !important;
  }
}
