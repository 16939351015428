.class-svg svg {
  align-items: center;
  display: flex;
}

.class-tiny svg {
  height: 23.45px;
}

.class-small svg {
  height: 33.45px;
}

.class-medium svg {
  height: 43.45px;
}

.class-large svg {
  height: 53.45px;
}
