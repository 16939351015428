.class-ProjectForm {
  gap: 20px 0;

  .MuiInputAdornment-root.MuiInputAdornment-positionEnd.MuiInputAdornment-outlined.MuiInputAdornment-hiddenLabel.MuiInputAdornment-sizeSmall.css-1lfszb1-MuiInputAdornment-root > p {
    padding-right: 20px;
  }

  .MuiGrid-root.css-qquf8x-MuiGrid-root,
  .MuiGrid-root.css-1i0btkx {
    background: #eaeef1;
    padding: 20px;
    border-radius: 20px;
    margin: 20px 0;
    border: 2px dashed #0083d2;
    color: #0083d2;
    display: flex;
    flex-direction: column;

    button {
      background: #0083d2;
      width: 52px;
      height: 52px;
      border-radius: 1000px;
    }

    button:hover {
      background: #0798f1;
    }

    p {
      text-decoration: underline;
    }
  }

  .MuiGrid-root.css-qquf8x-MuiGrid-root:hover {
    background-color: #0082d23d;
  }

  .class-deleteButton > button svg {
    color: #e11f1f;
  }

  input#users-autocomplete {
    height: 45px !important;
    position: relative;
    bottom: 0;
  }

  .css-15ovows-MuiAutocomplete-root
    .MuiOutlinedInput-root
    .MuiAutocomplete-endAdornment {
    right: 12px;
    bottom: 5px;
    position: absolute;
  }

  .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-sizeSmall.MuiInputBase-adornedEnd.MuiInputBase-hiddenLabel.css-9ab9vi-MuiInputBase-root-MuiOutlinedInput-root,
  .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-sizeSmall.MuiInputBase-adornedEnd.MuiInputBase-hiddenLabel.css-2pbrfp {
    position: relative;

    > div:nth-child(2) {
      position: absolute;
      right: 0px;

      svg {
        color: #0083d2;
        margin-right: 6px !important;
      }
    }
  }

  > div {
    > .MuiGrid-item {
      padding-left: 37px;
    }
    > .MuiGrid-item:nth-child(11) {
      padding: 0;
      padding-left: 37px;
      padding-top: 16px;
    }
    .class-buttonPlus > button {
      background: #0083d2;
      width: 52px;
      height: 52px;
      position: relative;
      // bottom: -20px;
      // right: -22px;
      border-radius: 1000px;

    }

    > div:last-child {
      display: flex;
      justify-content: space-between;

      > button {
        max-width: 200px;
        border-radius: 1000px;
        box-shadow: 0px 2px 0px 0px #0002;
      }
      > button:first-child {
        background-color: #eaeef1;
        color: #0083d2;
      }
      > button:last-child {
        background-color: #0083d2;
        color: #eaeef1;
      }
    }
  }

  input#outlined-basic {
    border: none !important;
  }
  input {
    background-color: #eaeef1 !important;
    border: 1px solid #707070 !important;
    height: 34px !important;
    border-radius: 1000px !important;
  }
  .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-sizeSmall.MuiInputBase-multiline.MuiInputBase-hiddenLabel.css-swq30s-MuiInputBase-root-MuiOutlinedInput-root {
    padding: 8.5px 0;
  }
  textarea {
    background-color: #eaeef1 !important;
    border: 1px solid #707070 !important;
    height: 140px !important;
    border-radius: 20px !important;
    padding: 20px !important;
  }

  .\31 000px.\31 000px.MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input.css-1wiwf6i-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
  .\31 000px.\31 000px.MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input.css-eg48z8 {
    background-color: #eaeef1 !important;
    border: 1px solid #707070 !important;
    height: 34px !important;
    border-radius: 1000px !important;
    align-items: center;
    display: flex;
  }

    .css-15ovows-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
        right: 12px;
        bottom: 5px;
        position: absolute;
    }

    .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-sizeSmall.MuiInputBase-adornedEnd.MuiInputBase-hiddenLabel.css-9ab9vi-MuiInputBase-root-MuiOutlinedInput-root,
    .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-sizeSmall.MuiInputBase-adornedEnd.MuiInputBase-hiddenLabel.css-2pbrfp {
        position: relative;

        > div:nth-child(2) {
            position: absolute;
            right: 0px;

            svg {
                color: #0083d2;
            }
        }
    }

    > div {
        > .MuiGrid-item {
            padding-left: 37px;
        }
        > .MuiGrid-item:nth-child(11){
            padding: 0;
            padding-left: 37px;
            padding-top: 16px;
        }
        .class-buttonPlus > button {
            background: #0083d2;
            width: 52px;
            height: 52px;
            position: relative;
            // bottom: -20px;
            // right: -22px;
            border-radius: 1000px;
        }

        > div:last-child {
            display: flex;
            justify-content: space-between;

            > button {
                max-width: 200px;
                border-radius: 1000px;
                box-shadow: 0px 2px 0px 0px #0002;
            }
            > button:first-child {
                background-color: #EAEEF1;
                color: #0083D2;
            }
            > button:last-child {
                background-color: #0083D2;
                color: #EAEEF1;
            }
        }
    }

    input#outlined-basic {
        border: none !important;
    }
    input {
        background-color: #EAEEF1 !important;
        border: 1px solid #707070 !important;
        height: 34px !important;
        border-radius: 1000px !important;
    }
    .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-sizeSmall.MuiInputBase-multiline.MuiInputBase-hiddenLabel.css-swq30s-MuiInputBase-root-MuiOutlinedInput-root {
        padding: 8.5px 0;
    }
    textarea {
        background-color: #EAEEF1 !important;
        border: 1px solid #707070 !important;
        height: 140px !important;
        border-radius: 20px !important;
        padding: 20px !important;
    }

    .\31 000px.\31 000px.MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input.css-1wiwf6i-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
    .\31 000px.\31 000px.MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input.css-eg48z8 {
        background-color: #EAEEF1 !important;
        border: 1px solid #707070 !important;
        height: 34px !important;
        border-radius: 1000px !important;
        align-items: center;
        display: flex;

    }
}

.css-9ab9vi-MuiInputBase-root-MuiOutlinedInput-root {
  padding-right: 0 !important;
}

.css-hmyn22-MuiButtonBase-root-MuiIconButton-root {
  margin-right: 6px !important;
}

.class-buttonPlus {
    padding: 0 !important;
    margin: 0 !important;
    height: 78px;
    display: flex;
    justify-content: end;
    align-self: center;
}

// .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-sizeSmall.MuiInputBase-adornedEnd.MuiInputBase-hiddenLabel.css-9ab9vi-MuiInputBase-root-MuiOutlinedInput-root {
//     background-color: #eaeef1 !important;
//     border: 1px solid #707070 !important;
//     height: 53px !important;
//     border-radius: 1000px !important;
// }
