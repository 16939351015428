.root {
    display: flex;
    position: relative;
}
.leftMenu {
    position: relative;
    height: 100%;
    margin-right: 20px;
}
.searchFilter {
    position: relative;
    width: 85%;
    margin-right: 50px;
    &[data-size=true] {
        width: 55%;
    }
    
}
.user {
    position: relative;

}