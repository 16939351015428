.class-TechLevel {
  div.MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input {
    background-color: #eaeef1 !important;
    border: 1px solid #707070 !important;
    border-radius: 1000px !important;
    height: 34px !important;
    display: flex;
    align-items: center;
  }

  tbody > tr > td:last-child button svg {
    color: #e11f1f;
  }
}
