.storybook-button {
  border: 0;
  border: none !important;
  border-radius: 3em;
  box-shadow: none !important;
  cursor: pointer;
  display: inline-block;
  font-family: 'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 700;
  gap: 10px;
  line-height: 1;
  min-height: 62px;
  padding: 0 20px !important;
}

.storybook-button--primary {
  background-color: #1ea7fd;
  color: white;
}

.storybook-button--secondary {
  background-color: transparent;
  box-shadow: rgba(0, 0, 0, 0.15) 0 0 0 1px inset;
  color: #333333;
}

.storybook-button--small {
  font-size: 12px;
  padding: 10px 16px;
}

.storybook-button--medium {
  font-size: 14px;
  padding: 11px 20px;
}

.storybook-button--large {
  font-size: 16px;
  padding: 12px 24px;
}

.class-only-icon {
  height: 62px;
  width: 62px;
}

.class-borderRaius-all {
  border-radius: 30px !important;
}

.class-borderRaius-left {
  border-radius: 30px 0 0 30px !important;
}

.class-borderRaius-right {
  border-radius: 0 30px 30px 0 !important;
}

.class-borderRaius-none {
  border-radius: 0 !important;
}

.css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
  background: #eaeef1;
  border: 1px solid #828689 !important;
  color: #828689 !important;
}

/* button svg {
  width: 20px;
  fill: #227bd4;
} */

/* button.class-isIconOnly svg {
  fill: #eaeef1;
} */

button.class-isIcon-search svg {
  fill: #828689;
}

button.class-RowReverse {
  display: flex;
  flex-direction: row-reverse;
}
