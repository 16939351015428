.class-input-clasic {
  background-color: #eaeef1;
  border: 1px solid #707070;
}

.class-all-border,
.class-all-border > div:last-child {
  border-radius: 30px;
}

.class-border-left,
.class-border-left > div:last-child {
  border-radius: 30px 0 0 30px;
  height: 100%;
}

.class-border-right,
.class-border-right > div:last-child {
  border-radius: 0 30px 30px 0;
}


.class-inputDate .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root,
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  pointer-events: auto;
  -webkit-transform: translate(14px, -20px) scale(0.75) !important;
  -moz-transform: translate(14px, -20px) scale(0.75) !important;
  -ms-transform: translate(14px, -20px) scale(0.75) !important;
  transform: translate(14px, -20px) scale(0.75) !important;
  user-select: none;
  z-index: 1;
}




.class-input-clasic label.MuiInputLabel-root:not(.Mui-focused) {
  transform: translate(22px, 18px) scale(1)
}
.class-input-clasic label.MuiInputLabel-root.Mui-focused,
.class-input-clasic label.MuiInputLabel-root.MuiFormLabel-filled {
  transform: translate(22px, 4px) scale(0.8)
}

legend.css-1in441m,
legend.css-1ftyaf0 {
  display: none;
}

fieldset.MuiOutlinedInput-notchedOutline {
  top: 0px;

  /* height: 56px; */
}

.flex-auto{
  flex: auto;
}

input.MuiOutlinedInput-input{
  padding-left: 22px;
}