.root,
.root2 {
  background-color: #f5f7f8;
  height: 100%;
  border-radius: 15px;
  transition: 0.3s;
}

.root {
  width: 300px;
  ul {
    width: 300px;
    li {
      min-height: 70px !important;
      div {
        svg {
          fill: #0083d2;
        }
      }
      p {
        color: #0083d2;
      }
    }
  }
}

.root2 {
  width: 80px;
  ul {
    width: 80px;
    li {
      display: flex;
      justify-content: space-evenly;
      min-height: 70px !important;
      div {
        display: flex;
        align-content: stretch;
        justify-content: space-evenly;
        svg {
          fill: #0083d2;
        }
      }
      p {
        transition: 0.3s;
        display: none;
      }
    }
  }
}

.logoPanda {
  width: 80%;
  height: 70px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 0px 15px;
  margin-left: 10px;
  transition: 0.2s ease-out;
  cursor: pointer;  
  &[data-open='true'] {
    background-image: url('../../../../assets/img/Group8622x.png');
  }
  &[data-open='false'] {
    background-image: url('../../../../assets/img/Group4352x.png');
    width: 35%;
  }
}