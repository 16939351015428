.root {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-end;
  align-items: center;
  color: #828689;

  p {
    line-height: 16px;
    font-size: 18px;
    text-align: right;
    font-family: Arial, Helvetica, sans-serif, 'regular';
  }

  div {
    margin-right: 20px;
  }
}

.position {
  font-weight: bold;
}
