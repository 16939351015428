.class-MultipleSelectCheckmarks {
    background-color: #eaeef1;
    border: 1px solid #707070 !important;
    border-radius: 1000px;
    position: relative;
    z-index: 1 !important;
    width: 100% !important;
    bottom: 0;
    margin: 0 !important;

    > div:nth-child(2) {
        border-radius: 1000px;
    }

    div#demo-multiple-checkbox {
        padding: 8.5px 14px;
        height: 34px;
        align-items: center;
        display: flex;

    }
}

.css-6ad9h2-MuiFormLabel-root-MuiInputLabel-root {
    transform: translate(14px, -26px) scale(0.75) !important;
}