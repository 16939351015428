.class-SearchFilterComponent > form:first-child {
  display: flex;
  flex-direction: inherit;
  justify-content: space-between;
  gap: 0;
}

.class-SearchFilterComponent > form:first-child > div:first-child{
  background-color: rgb(234, 238, 241);
  width: calc(100% - (64px * 2));
}

.class-SearchFilterComponent > form:first-child > div:last-child > button{
  background: #0083d2;
}

.class-SearchFilterComponent
  > form:first-child
  > div:first-child
  > div:last-child {
  height: 62px;
}

.class-SearchFilterComponent > div:last-child {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-around;
  max-width: 550px;
}

.class-SearchFilterComponent > div:last-child > h5 {
  color: #828689;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.class-SearchFilterComponent .class-FilterComponent {
  min-width: fit-content;
}
