.class-projectDetail {
    padding-left: 12px;
    color: #828689;

    > div > div:nth-child(2) > div {
        gap: 20px 0;

        > div {
            gap: 20px;
            align-self: flex-start;

            > div:first-child {
                background: transparent;
                padding: 0;
                margin: 0;
            }
            > div{
                background: #EAEEF1;
                border-radius: 20px;
                margin: 6px 0;
                width: 100%;
                padding: 10px 20px;
                display: grid;
                gap: 20px;
            }
        }
    }

    tbody > tr > td:last-child svg {
        display: none;
    }

    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiCard-root.css-1vea099-MuiPaper-root-MuiCard-root {
        background-color: #EAEEF1;
        box-shadow: none;
        display: flex;
        align-items: center;
        min-height: 80px;
        padding: 0 20px;
        color: #0083d2;

        > div {
            padding: 0;

            .MuiBox-root.css-70qvj9 {
                gap: 20px;

                .css-ndawh1-MuiTypography-root-MuiLink-root {
                    margin: 0;
                    color: #0083d2;
                    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
                    margin-left: 0;
                    -webkit-text-decoration: underline;
                    text-decoration: underline;
                    text-decoration-color: rgb(0 131 210);
                }
            }
        }
    }
}