@media only screen and (max-width: 1700px) {
    .MuiBox-root.css-vgcmmp {
        margin: 5vh 2vw;
        width: 96vw;
        padding: 0;
    }
}

@media only screen and (max-width: 1500px) {
    * {
        font-size: 0.89rem;
    }
    path {
        font-size: 1.5rem !important;
    }
    .SideMenuLeft_root__HfaUB {
        width: 200px;
    }
    .SideMenuLeft_root__HfaUB ul {
        width: 200px;
        padding: 0;
    }
    .SideMenuLeft_logoPanda__CUcnn {
        width: calc(100% - 10px - (15px * 2));
    }
}

@media only screen and (max-width: 1110px) {
    .MuiBox-root.css-vgcmmp {
        margin: 20px;
        width: calc(100vw - 40px);
        padding: 0;
    }
    .SideMenuLeft_root__HfaUB {
        padding-right: 10px;
    }
    .css-14a936p {
        padding-left: 0 !important;
    }
    .UserLogged_root__73Gow {
        padding-right: 20px;
    }
}