.tinyRadio .MuiSvgIcon-root {
  font-size: 20px;
}

.smallRadio .MuiSvgIcon-root {
  font-size: 25px;
}

.mediumRadio .MuiSvgIcon-root {
  font-size: 36px;
}

.largeRadio .MuiSvgIcon-root {
  font-size: 40px;
}

.font-radio {
  font-size: 27px;
  font-weight: 300;
}

.font-radio.tinyRadio {
  font-size: 18px;
}

.font-radio.smallRadio {
  font-size: 22px;
}

.font-radio.mediumRadio {
  font-size: 27px;
}

.font-radio.largeRadio {
  font-size: 35px;
}
