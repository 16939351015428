.class-BioData {
  input:not(.css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input, .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input, .css-b52kj1, .css-1uvydh2) {
    background-color: #eaeef1 !important;
    border: 1px solid #707070 !important;
    height: 34px !important;
    border-radius: 1000px !important;
  }

  textarea {
    background-color: #eaeef1 !important;
    border: 1px solid #707070 !important;
    border-radius: 20px !important;
    height: 140px !important;
    padding: 20px !important;
  }

  svg.css-i4bv87-MuiSvgIcon-root,
  svg.css-vubbuv {
    color: #0083d2;
  }

  div.MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input {
    background-color: #eaeef1 !important;
    border: 1px solid #707070 !important;
    border-radius: 1000px !important;
    height: 34px !important;
    display: flex;
    align-items: center;
  }

  .css-swq30s-MuiInputBase-root-MuiOutlinedInput-root {
    padding: 0;
  }

  > div {
    > div:nth-child(3) {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      > div {
        button {
          background: #0083d2;
        }

        > div {
          width: 150px;
          height: 150px;
          border: 1px solid #2b3033;
        }
      }
    }

    > div:nth-child(4) {
      padding: 0;
      border-radius: 20px;
      border: 1px solid #707070;
      display: flex;
      flex-direction: column;
      margin-top: 20px;

      img.MuiBox-root.css-puo791 {
        display: block;
        -webkit-background-size: cover;
        background-size: cover;
        background-repeat: no-repeat;
        -webkit-background-position: center;
        background-position: center;
        width: 100%;
        object-fit: cover;
        border-radius: 20px;
      }

      button {
        background: #0083d2;
        width: 52px;
        height: 52px;
        border-radius: 1000px;
      }

      p {
        text-decoration: underline;
      }
    }

    > div:nth-child(7) > div > div {
      background-color: #eaeef1 !important;
      border: 1px solid #707070 !important;
      border-radius: 1000px !important;
      height: 50px !important;
      justify-content: center;
    }

    > div:nth-child(8) > div > div {
      background-color: #eaeef1 !important;
      border: 1px solid #707070 !important;
      border-radius: 1000px !important;
      height: 50px !important;
      justify-content: center;
    }

    > div:nth-child(14) > div > div {
      background-color: #eaeef1 !important;
      border: 1px solid #707070 !important;
      border-radius: 1000px !important;
      height: 50px !important;
      justify-content: center;
    }

    > div:nth-child(13) > div > div {
      background-color: #eaeef1 !important;
      border: 1px solid #707070 !important;
      border-radius: 1000px !important;
      height: 50px !important;
      justify-content: center;
    }

    > div:nth-child(17) p.MuiFormHelperText-root.css-103wutb-MuiFormHelperText-root {
        display: none;
    }
  }
}

.class-BioData > div > div:nth-child(5) > div:nth-child(2) > div > div {
  background-color: #eaeef1 !important;
  border: 1px solid #707070 !important;
  border-radius: 1000px !important;
  height: 50px !important;
  justify-content: center;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1ffpnql .MuiOutlinedInput-root {
  padding-right: 12px !important;

  svg {
    color: #0083d2;
  }
}
