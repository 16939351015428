.class-inputDropdown {
  background-color: #eaeef1;

  /* border: 1px solid #707070; */
  max-width: 398px;
  position: relative;
  z-index: 1 !important;
}

.class-all-border,
.class-all-border > div:last-child,
.class-all-border > div > div:last-child {
  border-radius: 30px;
}

div.MuiAutocomplete-popper {
  background-color: rgb(214, 223, 229);
  border: 1px solid #707070;
  position: absolute;
  position: relative;
  top: 0px !important;
  z-index: 0 !important;
}

div.MuiAutocomplete-popper,
div.MuiAutocomplete-popper > div {
  color: #707070;
}

div.MuiAutocomplete-popper,
div.MuiAutocomplete-popper > div:last-child {
  border-radius: 10px 10px 30px 30px;
}

div.MuiAutocomplete-popper > div {
  background-color: transparent;
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  -webkit-transform: translate(14px, -20px) scale(0.75);
  -moz-transform: translate(14px, -20px) scale(0.75);
  -ms-transform: translate(14px, -20px) scale(0.75);
  transform: translate(14px, -20px) scale(0.75);
}
