.class-CardUserDetails {
  height: 210px;
  > div:first-child {
    position: relative;

    > div:last-child {
        position: absolute;
        bottom: 10px;
        right: 12px;
        width: max-content;
        display: flex;
        gap: 10px;
        z-index: 9;

        .css-2f015h-MuiButtonBase-root-MuiButton-root:hover {
          background-color: #111111 !important;
        }

        button {
          background: #0083d2;
          height: 40px;
          min-width: 40px;
          border-radius: 2000px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;

            svg {
              color: white;
              font-size: 16pt;
            }
        }
    }

    > div:first-child {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 20px;
      background: linear-gradient(
        0deg,
        rgb(28 33 36 / 57%) 0%,
        rgb(0 50 80 / 14%) 35%,
        rgb(245 247 248 / 0%) 100%
      );
    }
  }
  img.MuiCardMedia-root.MuiCardMedia-media.MuiCardMedia-img.css-o69gx8-MuiCardMedia-root,
  img.MuiCardMedia-root.MuiCardMedia-media.MuiCardMedia-img.css-rhsghg {
    border-radius: 20px;
  }
  .css-1hbtvmf {
    background-color: transparent;
    position: relative;
    top: -55px;

    > div:nth-child(2) {
      display: flex;
      align-items: center;
    }

    > div:nth-child(3) > div > p > div {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      align-items: center;
      align-content: center;
      gap: 10px;

      svg {
        padding: 0;
      }
    }
  }
}
