
::placeholder {
    color: #707070;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: #707070;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
   color: #707070;
  }

/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #707070;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #0083d2;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #0da2ff;
}