.class-getClient{
    > div {
        > div:first-child button {
            border: none;
        }

        > div:nth-child(2) {
            > div:first-child {
                display: grid;
                grid-template-columns: 33.333333% auto;
                gap: 16px;
                align-items: center;

                > div:first-child > div  {
                    background: #0288d1;
                    width: 100%;

                    img {
                        width: auto;
                        height: 100%;
                        text-align: center;
                        object-fit: cover;
                        color: transparent;
                        text-indent: 10000px;
                    }
                }

                > div:nth-child(2) {
                    gap: 20px 0;

                    > div:last-child {
                        gap: 20px;

                        > div {
                            align-items: center;
                            display: flex;
                            gap: 20px;
                            color: #0288d1;
                        }
                    }
                }
            }
            > div:nth-child(3) {
                > div:nth-child(2) {
                    > div {
                        > div {
                            > div {
                                width: calc(100% - 20px);
                                padding: 0;
                                display: flex;
                                align-content: center;
                                align-self: center;
                                flex-wrap: wrap;
                                background: #EAEEF1 0% 0% no-repeat padding-box;
                                box-shadow: 0px 3px 6px rgb(28 33 36 / 31%);
                                border-radius: 30px;
                                opacity: 1;
                                margin: 10px 20px;

                                > div {
                                    padding: 20px;
                                    width: 100%;

                                    img {
                                        background: #f1f1f1;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            > div:nth-child(4) {
                > div:nth-child(2) {
                    > div {
                        > div {
                            > div {
                                background: #EAEEF1 0% 0% no-repeat padding-box;
                                box-shadow: 0px 3px 6px rgb(0 0 0 / 32%);
                                border: 3px solid #EAEEF1;
                                border-radius: 30px;
                                opacity: 1;
                                width: calc(100% - 45px);
                                margin: 20px 10px;

                                svg {
                                    color: #0288d1;
                                }

                                img {
                                    background: #f1f1f1;
                                }
                            }
                        }
                    }
                }
            }
            > div:nth-child(5) {
                > div:nth-child(2) {
                    > div {
                        > div {
                            > div {
                                background: #EAEEF1 0% 0% no-repeat padding-box;
                                box-shadow: 0px 3px 6px #00000029;
                                border-radius: 30px;
                                opacity: 1;

                                > div {
                                    padding: 20px;

                                    svg,
                                    a {
                                        color: #0288d1;
                                        text-decoration-color: #0288d1;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/*responsive of widht 1400px*/
@media only screen and (max-width: 1300px) {
    .class-getClient > div > div:nth-child(2) > div:first-child {
        display: grid;
        grid-template-columns: auto;
    }
  }