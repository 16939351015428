.class-ClientForm{
    // background-color: red;
    input {
        background-color: #EAEEF1 !important;
        border: 1px solid #707070 !important;
        height: 34px !important;
        border-radius: 1000px !important;
    }

    textarea {
        background-color: #EAEEF1 !important;
        border: 1px solid #707070 !important;
        height: 140px !important;
        border-radius: 20px !important;
        padding: 20px !important;
    }

    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-sm-12.css-h719to:nth-last-child(2) {
        margin-left: 32px;
    }

    .MuiGrid-root.css-qquf8x-MuiGrid-root,
    .class-ProjectForm .MuiGrid-root.css-1i0btkx,
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-sm-12.css-h719to:nth-last-child(2) {
        background: #EAEEF1;
        padding: 20px;
        border-radius: 20px;
        margin: 20px 0;
        border: 2px dashed #0083d2;
        color: #0083d2;
        display: flex;
        flex-direction: column;

        button {
            background: #0083d2;
            width: 52px;
            height: 52px;
            border-radius: 1000px;
        }

        p {
            text-decoration: underline;
        }
    }

    tbody > tr > td:last-child {
        display: flex;
        justify-content: right;
        width: auto;

        button svg {
            color: #E11F1F;
        }
    }

    .\31 000px.\31 000px.MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input.css-1wiwf6i-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
    .class-ProjectForm .\31 000px.\31 000px.MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input.css-eg48z8,
    .\31 000px.\31 000px.MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input.css-eg48z8 {
        background-color: #EAEEF1 !important;
        border: 1px solid #707070 !important;
        height: 34px !important;
        border-radius: 1000px !important;
    }

    > div {
        padding-left: 37px;

        > div:nth-child(2) > div {
            .MuiAvatar-root.MuiAvatar-circular.css-1hed25n-MuiAvatar-root,
            .MuiAvatar-root.MuiAvatar-circular.css-13eimya {
                width: 100%;
                border-radius: 40px;
                background-color: #EAEEF1 !important;
                border: 1px solid #707070 !important;

                // img {
                //     opacity: 0.04;
                // }
            }
            button {
                left: 97% !important;
                background: #0083d2;
                width: 52px !important;
                height: 52px !important;
                border-radius: 1000px;

                svg {
                    font-size: 12pt;
                }
            }
        }

        > div:nth-child(4) {
            label#google-map-demo-label,
            .css-6ad9h2-MuiFormLabel-root-MuiInputLabel-root {
                top: -13px !important;
                font-size: 9pt;
                left: 24px !important;
                color: #46505A;
                font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
                font-weight: 400;
                line-height: 1.4375em;
                padding: 0;
                position: relative;
                display: block;
                transform-origin: top;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: calc(133% - 24px);
                position: absolute;
                -webkit-transform: none;
                -moz-transform: none;
                -ms-transform: none;
                transform: none;
                -webkit-transition: none;
                transition: none;
                z-index: 1;
                pointer-events: auto;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
            }

            .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiInputBase-hiddenLabel.MuiAutocomplete-inputRoot.css-11tj6yu-MuiInputBase-root-MuiOutlinedInput-root,
            .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiInputBase-hiddenLabel.MuiAutocomplete-inputRoot.css-16weo2n {
                padding-right: 0;
                position: relative;

                input#google-map-demo {
                    width: 100%;
                    z-index: 9;
                    padding-left: 20px;
                }

                .MuiInputAdornment-root.MuiInputAdornment-positionEnd.MuiInputAdornment-outlined.MuiInputAdornment-hiddenLabel.MuiInputAdornment-sizeMedium.css-1lfszb1-MuiInputAdornment-root,
                .MuiInputAdornment-root.MuiInputAdornment-positionEnd.MuiInputAdornment-outlined.MuiInputAdornment-hiddenLabel.MuiInputAdornment-sizeMedium.css-14rfkkp {
                    position: absolute;
                    right: 15px;
                    z-index: 9;
                }

            }
        }

        > div:nth-child(9) > div {
            > span {
                padding: 0 20px;
            }
            > div:nth-child(2) > div {
                padding: 8.5px 0;
            }
        }

        > div:nth-child(10) {
            > div:nth-child(2) {
                .MuiAvatar-root.MuiAvatar-circular.css-1hed25n-MuiAvatar-root,
                .css-13eimya,
                .MuiAvatar-root.MuiAvatar-circular.css-13eimya {
                    width: 100px;
                    height: 100px;
                    border: 2px solid #2b3033;
                }
                button {
                    left: 74px !important;
                }
            }
            button {
                background: #0083d2;
                width: 52px !important;
                height: 52px !important;
                border-radius: 1000px;

                svg {
                    font-size: 12pt;
                }
            }
            > div:last-child {
                justify-content: right;
                display: flex;
            }
        }

        > div:last-child {
            display: flex;
            justify-content: space-between;

            > button {
                width: 200px;
                border-radius: 1000px;
            }

            > button:first-child {
                background-color: #EAEEF1;
                color: #0083D2;
            }

            > button:last-child {
                background-color: #0083D2;
                color: #EAEEF1;
            }
        }
    }
}

// div.MuiAutocomplete-popper{
//     left: 5px !important;
//     max-width: 20.5vw !important;
// }