button.back-button {
  width: 200px;
  border-radius: 1000px;
  background-color: #eaeef1;
  color: #0083d2;
  margin-bottom: 10px;
}

.class-employeeDetail,
.class-profile {
  .MuiBox-root.css-1n3h4wo {
    display: grid;
    width: 100%;
    grid-template-columns: 200px calc(100vw - 6vw - 300px - 200px - 170px);
    margin-top: 20px;
    background: transparent;

    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-4.css-nokp1d-MuiGrid-root {
      padding: 8px 16px 8px 0;

      > p {
        background: #EAEEF1;
        border-radius: 20px;
        margin: 6px 0px;
        padding: 20px;
      }
    }
  }
}

@import "./Responsive.scss";
@import "./Scrollbar.scss";
