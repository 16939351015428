.class-CardTeamMember {
  background-color: #eaeef1 !important;
  border: 3px solid #eaeef1;
  border-radius: 30px !important;
  box-shadow: 0 3px 6px 6px #1c212409 !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  overflow: inherit !important;
  position: relative;
}

.class-CardTeamMember > span:first-child {
  display: flex;
  flex-direction: column;
  gap: 13px;
  position: absolute;
  right: -33px;
  z-index: 1;
}

.class-CardTeamMember > div:nth-child(2) {
  border-radius: 30px 30px 0 0;
  height: 50%;
  width: 100%;
}

.class-CardTeamMember > div:nth-child(3) {
  background: #bf8b5e;
  border-radius: 0 30px 30px 0;
  color: #f5f5f5;
  left: 0;
  padding: 5px 10px;
  padding-right: 20px;
  position: absolute;
  top: 40%;
}

.class-CardTeamMember > div:nth-child(3).class-InBranch,
.class-cardResult > div:nth-child(3).class-InBranch {
  background: #bf8b5e;
}

.class-CardTeamMember > div:nth-child(3).class-InProject,
.class-cardResult > div:nth-child(3).class-InProject {
  background: #618c03;
}

.class-CardTeamMember > div:nth-child(3).class-Suspended,
.class-cardResult > div:nth-child(3).class-Suspended {
  background: #828689;
}

.class-CardTeamMember > div:last-child {
  display: flex;
  justify-content: flex-end;
}

.class-cardResult {
  background-color: #f5f7f8 !important;
  border: 3px solid #f5f7f8;
  border-radius: 30px !important;
  box-shadow: 0 3px 6px 6px #1c212409 !important;
  display: grid;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  gap: 10px;
  justify-items: center;
  max-width: 260px !important;
  overflow: inherit !important;
  padding: 10px 0;
  position: relative;
  text-align: center;
}

.class-cardResult > span:first-child {
  height: 190px;
  position: relative;
  width: 190px;
  z-index: 1;
}

.class-cardResult > span:first-child > button {
  border: 2px solid #f5f7f8 !important;
  position: absolute;
}

.class-cardResult > span:first-child > button:first-child {
  right: 14%;
  top: 6%;
}

.class-cardResult > span:first-child > button:nth-child(2) {
  right: 4%;
  top: 24%;
}

.class-cardResult > span:first-child > button:nth-child(3) {
  right: 0%;
  top: 45%;
}

.class-cardResult > div:nth-child(2) {
  position: absolute;
  top: calc(120px / 2);
}

.class-cardResult > div:nth-child(3) {
  background: #bf8b5e;
  border-radius: 30px;
  color: #f5f5f5;
  grid-row: 3;
  padding: 5px 10px;
}

.class-cardResult > div:nth-child(4) {
  padding: 0;
}

.class-cardResult > div:nth-child(4) > div {
  color: #828689;
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0;
}
